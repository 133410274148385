import './index.scss'

$('.tab_header_btn').on('click', function () {

    $('.tab-angle').removeClass('tab-angle-active')
    const tabHeaderContent = $(this).parents('.tab_header__item');
    const tabHeaderContentWeight = tabHeaderContent.width();
    const id_content = $(tabHeaderContent).data('id_content');
    const content_height = heightContent(id_content)
    console.log( );
    

   
    
    if(document.getElementById(id_content).classList.contains('tab_main_item-active')){
        $('.tab_wrap').removeClass('tab-active')
        $('.tab-angle').removeClass('tab-angle-active')
        clearTab('.tab_main_item', 'tab_main_item-active');
        
        $(`#${id_content}`).parents('.tab_main').css('min-height', 0);
   
        
    }else{
        clearTab('.tab_main_item', 'tab_main_item-active');
        activeContent(id_content, content_height);

        $('.tab-angle').css('left', tabHeaderContentWeight / 2 + $(tabHeaderContent).offset().left)
    
        $('.tab_wrap').addClass('tab-active')
        $('.tab-angle').addClass('tab-angle-active')
    
    }
    

    // activeHeaderTab()
})

function clearTab(tabClass, tavClassActive) {
    $(tabClass).removeClass(tavClassActive)
}
function heightContent(element) {
    return document.getElementById(element).offsetHeight;
}
function activeContent(idContent, contentHeight) {
    $(`#${idContent}`).parents('.tab_main').css('min-height', contentHeight);
    $(`#${idContent}`).addClass('tab_main_item-active')
}

let select_direction = $('.select_direction').select2({
    dropdownAutoWidth: true,

    width: 'resolve',

    placeholder: gettext("Напрям"),
    maximumSelectionLength: 3,
    "language": {
        "noResults": function () {
            return gettext("Напрямку не знайдено");
        }
    },

});
let select_nomenclature = $('.select_nomenclature').select2({
    dropdownAutoWidth: true,
    width: 'resolve',
    disabled: true,
    placeholder: gettext("Номенклатура"),
    "language": {
        "noResults": function () {
            return gettext("Номенклатури не знайдено");
        }
    },

});
fetch(`/api/active_directions/`)
    .then(function (response) {
        return response.json()
    })
    .then(function (response) {
        for (var key in response) {
            var option = new Option(response[key].name, response[key].id, false, false);
            select_direction.append(option).trigger('change');
        }
    })


$(select_direction).on("select2:select", function (e) {
    $(select_nomenclature).select2('enable');
    $('.select_direction').parent().find('.filter_input').val(e.params.data.id);


    let select_value = e.params.data.id;

    let query = '/api/active_nomenclatures/';

    query += `?direction_id=${select_value}`;


    fetch(query)
        .then(function (response) {
            return response.json()
        })
        .then(function (response) {
            console.log(response);
            // var option = new Option('Усі райони', '', false, true);
            // select_nomenclature.append(option).trigger('change');
            for (var key in response) {
                var option = new Option(response[key].name, response[key].id, false, false);
                select_nomenclature.append(option).trigger('change');
            }
        })

});

$(select_nomenclature).on("select2:select", function (e) {
    $('.tender_button').find('.btn').removeClass('btn-standart-disable')
    $('.select_nomenclature').parent().find('.filter_input').val(e.params.data.id);


    if ($('.tender_price_box').hasClass('tender_price_box-active')) {
        const nomenclature = $("input[name='select_nomenclature']").val()
        let query = '/api/rozrah/';

        query += `?nomen_id=${nomenclature}`;



        fetch(query)
            .then(function (response) {
                return response.json()
            })
            .then(function (response) {
                console.log(response);

                var tpl = document.querySelector('.tender_price__wrap');
                tpl.innerHTML = '';

                for (var key in response) {
                    tpl.innerHTML += `<div class=" tender_price">
                        <div class="tender_price-date">${response[key].datetime}</div>
                        <div class="tender_price-value">${response[key].price}</div>
                    </div>`;
                }

                $('.tender_price_box').addClass('tender_price_box-active');
                $('.tender_button').addClass('tender_button-active')

            })

    }

})

$('.btn-tender').on('click', function () {
    event.preventDefault()
    if (!$(this).hasClass('btn-standart-disable')) {
        const nomenclature = $("input[name='select_nomenclature']").val()
        let query = '/api/rozrah/';

        query += `?nomen_id=${nomenclature}`;



        fetch(query)
            .then(function (response) {
                return response.json()
            })
            .then(function (response) {
                console.log(response);

                var tpl = document.querySelector('.tender_price__wrap');
                tpl.innerHTML = '';

                for (var key in response) {
                    tpl.innerHTML += `<div class=" tender_price">
                    <div class="tender_price-date">${response[key].datetime}</div>
                    <div class="tender_price-value">${response[key].price}</div>
                </div>`;
                }

                $('.tender_price_box').addClass('tender_price_box-active');
                $('.tender_button').addClass('tender_button-active')

            })

    }
})







$('.btn_link_anchor').on('click', function () {
    event.preventDefault();
    const id_content = $(this).attr('href');
    scroll_tar(id_content)


})

if(localStorage.getItem('idAnchor')){
    const id_content = localStorage.getItem('idAnchor');
    delete localStorage.idAnchor;
    scroll_tar(id_content)

} 
function scroll_tar(id_content){

    $('.tab-angle').removeClass('tab-angle-active')
    const tabHeaderContent = $(`[data-id_content='${id_content}']`);
    const tabHeaderContentWeight = tabHeaderContent.width();
    const content_height = heightContent(id_content)

    const tabOfset = $(tabHeaderContent).offset();
    $('body,html').animate({scrollTop: tabOfset.top }, 1000);
    clearTab('.tab_main_item', 'tab_main_item-active')
    activeContent(id_content, content_height);

    $('.tab-angle').css('left', tabHeaderContentWeight / 2 + $(tabHeaderContent).offset().left)

    $('.tab_wrap').addClass('tab-active')
    $('.tab-angle').addClass('tab-angle-active')

}